import * as React from "react";
import ResidentsAssociationList from "../../templates/ResidentsAssociationList";
import { generatePath, useHistory } from "react-router";
import {
  NEW_RESIDENTS_ASSOCIATION,
  RESIDENTS_ASSOCIATION_INFO,
} from "../../../routes/route";
import { IResidentsAssociationDetail } from "../../../types/state";
import { useQuery } from "react-query";
import { Api } from "../../../libs/api";
import { residentsAssociationsRequest } from "../../../api/residents_association";
import { AccountContext } from "../../../context/account";
import LoadingFullScreen from "../../templates/LoadingFullScreen";

const IndexResidentsAssociation: React.FC = () => {
  const { accountState } = React.useContext(AccountContext);
  const history = useHistory();

  const [state, setState] = React.useState({
    residentsAssociations: [] as IResidentsAssociationDetail[],
  });
  const { isLoading, isError, data } = useQuery("residentsAssociations", () =>
    Api.fetch(residentsAssociationsRequest(accountState.accessToken))
  );

  React.useEffect(() => {
    (async () => {
      if (!isLoading) {
        if (!isError) {
          setState((s) => ({
            ...s,
            residentsAssociations: data.residents_associations || [],
          }));
        }
      }
    })();
  }, [isLoading, isError, data]);

  const toBuildNewResidentsAssociation = React.useCallback(
    () => history.push(NEW_RESIDENTS_ASSOCIATION),
    [history]
  );
  const toResidentsAssociationInfoFunc = React.useCallback(
    (id) => {
      history.push(generatePath(RESIDENTS_ASSOCIATION_INFO, { id }));
    },
    [history]
  );

  return isLoading ? (
    <LoadingFullScreen />
  ) : (
    <ResidentsAssociationList
      toBuildNewResidentsAssociation={toBuildNewResidentsAssociation}
      toResidentsAssociationInfo={toResidentsAssociationInfoFunc}
      residentsAssociations={state.residentsAssociations}
    />
  );
};

export default IndexResidentsAssociation;
