import React from "react";
import { Loading } from "../../atoms/Loading";
import styles from "./LoadingFullScreen.module.scss";

export interface LoadingFullScreenProps {
  message?: string;
  withAnimation?: boolean;
}
export const LoadingFullScreen: React.FC<LoadingFullScreenProps> = ({
  message = "読み込み中です",
  withAnimation = true,
}) => {
  return (
    <div className={styles.loader}>
      <div>
        <Loading withAnimation={withAnimation} />
        <div className={styles.message}>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};
export default LoadingFullScreen;
