import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { AccountContext } from "../context/account";
import { LocalStorageKey } from "../definition";
import Login from "../components/pages/Login";
import AuthToken from "../components/pages/AuthToken";
import * as Routes from "./route";

export interface NoAuthedAppProps {
  accessToken: string | null;
}
export const NoAuthedApp: React.FC<NoAuthedAppProps> = ({ accessToken }) => {
  const { accountState } = React.useContext(AccountContext);
  React.useEffect(() => {
    if (accessToken && !accountState.accessToken) {
      localStorage.removeItem(LocalStorageKey.AccessToken);
      window.location.href = Routes.LOGIN;
    }
  }, [accessToken, accountState.accessToken]);
  return (
    <Router>
      <Switch>
        <Route exact={true} path={Routes.LOGIN}>
          {accessToken && accountState.accessToken ? <AuthToken /> : <Login />}
        </Route>
        {accessToken && accountState.accessToken ? (
          <AuthToken />
        ) : (
          <Redirect
            to={{
              pathname: Routes.LOGIN,
            }}
          />
        )}
      </Switch>
    </Router>
  );
};

export default NoAuthedApp;
