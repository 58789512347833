import * as React from "react";
import { AccountContext, setLoginResult } from "../../../context/account";
import { Api } from "../../../libs/api";
import { loginRequest } from "../../../api/account";
import LoginForm from "../../templates/LoginForm";
import { LocalStorageKey } from "../../../definition";

const Login: React.FC = () => {
  const { accountDispatch } = React.useContext(AccountContext);
  const [state, setState] = React.useState({ loading: false, error: "" });

  const loginFunc = React.useCallback(
    (email: string, password: string) => {
      (async () => {
        Api.fetch(loginRequest(email, password))
          .then(async (res) => {
            localStorage.setItem(LocalStorageKey.AccessToken, res.token);
            accountDispatch(setLoginResult(res));
          })
          .catch((e) => {
            setState((s) => ({ ...s, error: e.message }));
          });
      })();
    },
    [accountDispatch]
  );

  return <LoginForm login={loginFunc} error={state.error} />;
};
export default Login;
