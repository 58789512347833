import { LocalStorageKey } from "../definition";

interface Request {
  path: string;
  accessToken?: string;
  method: string;
  data?: {
    [key: string]: string | number | boolean;
  };
}

const _fetch = (request: Request) => {
  let url = `${process.env.REACT_APP_API_URL}${request.path}`;
  let headers: { [key: string]: string } = {
    Accept: "application/json",
  };
  if (request.accessToken) {
    headers["Authorization"] = `Bearer ${request.accessToken}`;
  }
  const params: RequestInit = {
    credentials: "omit",
    headers: headers as any,
    method: request.method,
  };
  headers["Content-Type"] = "application/json; charset=utf-8";
  if (request.method.match(/get/i)) {
    if (request.data) {
      url =
        url +
        "?" +
        Object.keys(request.data)
          .map((k) => encodeURI(k) + "=" + encodeURI(`${request.data![k]}`))
          .join("&");
    }
  } else if (request.method === "POST" || request.method === "PUT") {
    headers["Content-Type"] = "application/json; charset=utf-8";
    params.body = JSON.stringify(request.data || {});
  } else {
    const formData = new FormData();
    Object.keys(request.data || {}).forEach((name) => {
      formData.append(name, `${request.data![name]}`);
    });
    params.body = formData;
  }
  return fetch(url, params).then(async (data) => {
    if (data.status === 403) {
      localStorage.removeItem(LocalStorageKey.AccessToken);
      window.location.reload();
    }
    if (data.ok) {
      const res = await data?.clone().json();
      return res;
    } else {
      switch (data.status) {
        case 400:
          const res = await data?.clone().json();
          throw Error(res.message);
        case 401:
          throw Error("UNAUTHORIZED");
        case 500:
          throw Error("INTERNAL_SERVER_ERROR");
        case 502:
          throw Error("BAD_GATEWAY");
        case 404:
          throw Error("NOT_FOUND");
        default:
          throw Error("UNHANDLED_ERROR");
      }
    }
  });
};

export const Api = {
  fetch: _fetch,
};
