import React from "react";
import { AccountContext, logout, setAccount } from "../../../context/account";
import { Api } from "../../../libs/api";
import { authRequest } from "../../../api/account";
import LoadingFullScreen from "../../templates/LoadingFullScreen";

const AuthToken: React.FC = () => {
  const { accountDispatch, accountState } = React.useContext(AccountContext);

  React.useEffect(() => {
    (async () => {
      try {
        if (accountState.accessToken) {
          const res = await Api.fetch(authRequest(accountState.accessToken));
          accountDispatch(setAccount(res.account));
        }
      } catch (error) {
        accountDispatch(
          logout((error as { message: string }).message as string)
        );
      }
    })();
  }, [accountDispatch, accountState.accessToken]);

  return <LoadingFullScreen message="認証中" />;
};
export default AuthToken;
