import React from "react";
import { format } from 'date-fns';

import styles from "./ResidentsAssociationDetail.module.scss";
import { IResidentsAssociationDetail } from "../../../types/state";
import { AccountList } from "../../organisms/AccountList";

export interface ResidentsAssociationDetailProps {
  residentsAssociation: IResidentsAssociationDetail;
  toEditResidentsAssociation: (id: number) => void;
  toNewAccount: (residentsAssociationId: number) => void;
  toEditStatusBankAccount: (status: boolean) => void;
  toEditAccount: (id: number) => void;
}

export const ResidentsAssociationDetail: React.FC<ResidentsAssociationDetailProps> = (
  props
) => {
  const date = props.residentsAssociation.created_at
    ? format(new Date(props.residentsAssociation.created_at), 'yyyy/MM/dd HH:mm')
    : "";

  return (
    <div>
      <div className=" text-center pt-10 text-xl font-medium text-black">
        自治会詳細
      </div>
      <div className="m-24">
        <div className="flex flex-row-reverse">
          <button
            className={`${styles.btn}`}
            onClick={() => {
              props.toEditResidentsAssociation(props.residentsAssociation.id);
            }}
          >
            編集
          </button>
        </div>
        <div className="flex space-between gap-x-10">
          <div className="w-1/2">
            <div>
              <div className="font-black mt-5">自治会名</div>
              <div className="break-words">{props.residentsAssociation.name}</div>
            </div>
            <div>
              <div className="font-black mt-5">有効/無効</div>
              <div className="break-words">{props.residentsAssociation.serviced ? '有効' : '無効'}</div>
            </div>
            <div>
              <div className="font-black mt-5">住所</div>
              <div className="break-words">
                {`${props.residentsAssociation.prefecture}${props.residentsAssociation.city_name || ''}`}
              </div>
            </div>
            <div>
              <div className="font-black mt-5">自治会団体番号</div>
              <div className="break-words">{props.residentsAssociation.local_authority_code}</div>
            </div>
            <div>
              <div className="font-black mt-5">申請者名</div>
              <div className="break-words">{props.residentsAssociation.creator_name}</div>
            </div>
            <div>
              <div className="font-black mt-5">自治会メールアドレス</div>
              <div className="break-words">{props.residentsAssociation.email}</div>
            </div>
            <div>
              <div className="font-black mt-5">自治会番号採番日時</div>
              <div className="break-words">{date}</div>
            </div>
            <div>
              <div className="font-black mt-5">メモ</div>
              <div className="break-words">{props.residentsAssociation.memo}</div>
            </div>
          </div>
            {props.residentsAssociation.bank_account === null
            ? (<div className="mt-5">口座未登録</div>)
            : (<div className="w-1/2">
            <div>
              <div className="font-black mt-5">金融機関名</div>
              <div className="break-words">{props.residentsAssociation.bank_account.bank_name}</div>
            </div>
            <div>
              <div className="font-black mt-5">支店名</div>
              <div className="break-words">{props.residentsAssociation.bank_account.branch_name}</div>
            </div>
            <div>
              <div className="font-black mt-5">口座種類</div>
              <div className="break-words">{props.residentsAssociation.bank_account.account_type}</div>
            </div>
            <div>
              <div className="font-black mt-5">口座番号</div>
              <div className="break-words">{props.residentsAssociation.bank_account.account_number}</div>
            </div>
            <div>
              <div className="font-black mt-5">口座名義</div>
              <div className="break-words">{props.residentsAssociation.bank_account.account_holder_name}</div>
            </div>
            <div>
              <div className="font-black mt-5">口座名義(ｶﾅ)</div>
              <div className="break-words">{props.residentsAssociation.bank_account.account_holder_name_kana}</div>
            </div>
            <div>
              <div className="font-black mt-5">口座情報最終更新者</div>
              <div className="break-words">{props.residentsAssociation.bank_account.account_holder_email}</div>
            </div>
            <div>
              <div className="font-black mt-5">集金・支払いステータス無効/有効</div>
              <div className="relative inline-block w-16 mr-2 mt-2 align-middle select-none transition duration-200 ease-in">
                <input
                  type="checkbox"
                  checked={props.residentsAssociation.bank_account.status}
                  id="status"
                  onChange={(event) => {
                    const val = event.target.checked;
                    props.toEditStatusBankAccount(val);
                  }}
                  className={`${styles.toggleCheckbox} absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer`}
                />
                <label
                  htmlFor="status"
                  className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                ></label>
              </div>
            </div>
          </div>)}
        </div>
      </div>
      <div>
        <div className=" text-center pt-10 text-xl font-medium text-black">
          メンバー一覧
        </div>
        <div className={styles.wrapper}>
          <AccountList
            accounts={props.residentsAssociation.accounts!}
            toEditAccount={props.toEditAccount}
          />
          <div className="flex flex-row-reverse pt-10">
            <button
              className={styles.btn}
              onClick={() => {
                props.toNewAccount(props.residentsAssociation.id);
              }}
            >
              追加
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResidentsAssociationDetail;
