import * as React from "react";
import { IResidentsAssociationDetail } from "../../../types/state";
import { residentsAssociationRequest, updateStatusAccountBankRequest } from "../../../api/residents_association";
import { Api } from "../../../libs/api";
import { generatePath, useHistory, useParams } from "react-router";
import {
  EDIT_ACCOUNT,
  EDIT_RESIDENTS_ASSOCIATION,
  NEW_ACCOUNT,
} from "../../../routes/route";
import { AccountContext } from "../../../context/account";
import LoadingFullScreen from "../../templates/LoadingFullScreen";
import { useQuery } from "react-query";
import ResidentsAssociationDetail from "../../templates/ResidentsAssociationDetail";

export const ShowResidentsAssociation: React.FC = () => {
  const { accountState } = React.useContext(AccountContext);
  const { id } = useParams<{ id: string }>();
  const {
    isLoading,
    isError,
    data,
    error,
  } = useQuery(`residentsAssociation-${id}`, () =>
    Api.fetch(residentsAssociationRequest(accountState.accessToken, Number(id)))
  );

  const [state, setState] = React.useState({
    error: "",
    residentsAssociation: null as null | IResidentsAssociationDetail
  });

  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      if (!isLoading && !isError) {
        setState((s) => ({
          ...s,
          residentsAssociation: data.residents_association,
        }));
      }
    })();
  }, [isLoading, isError, error, data]);

  const toNewAccount = React.useCallback(
    (residentsAssociationId: number) =>
      history.push(generatePath(NEW_ACCOUNT, { residentsAssociationId })),
    [history]
  );
  const toEditResidentsAssociation = React.useCallback(
    (id) => {
      history.push(generatePath(EDIT_RESIDENTS_ASSOCIATION, { id }));
    },
    [history]
  );
  const toEditAccount = React.useCallback(
    (id) => {
      history.push(generatePath(EDIT_ACCOUNT, { id }));
    },
    [history]
  );
  const toEditStatusBankAccount = React.useCallback(async (status: boolean) => {
    if(state.residentsAssociation && state.residentsAssociation.bank_account) {
      const result = await Api.fetch(
        updateStatusAccountBankRequest(
          accountState.accessToken,
          state.residentsAssociation.id,
          status
        )
      );

      setState({
        ...state,
        residentsAssociation: {
          ...state.residentsAssociation,
          bank_account: {
            ...state.residentsAssociation.bank_account,
            status: result.status,
          },
        },
      });
    }
  },[state, accountState.accessToken]);

  return isLoading || !state.residentsAssociation ? (
    <LoadingFullScreen />
  ) : (
    <ResidentsAssociationDetail
      toNewAccount={toNewAccount}
      toEditAccount={toEditAccount}
      toEditResidentsAssociation={toEditResidentsAssociation}
      residentsAssociation={state.residentsAssociation}
      toEditStatusBankAccount={toEditStatusBankAccount}
    />
  );
};

export default ShowResidentsAssociation;
