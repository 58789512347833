import * as React from "react";
import AccountForm from "../../templates/AccountForm";
import { Account, ResidentsAssociation } from "../../../types/state";
import {
  accountDetailRequest,
  updateAccountRequest,
} from "../../../api/account";
import { Api } from "../../../libs/api";
import { generatePath, useHistory, useParams } from "react-router";
import { RESIDENTS_ASSOCIATION_INFO } from "../../../routes/route";
import { AccountContext } from "../../../context/account";
import { useMutation, useQuery, useQueryClient } from "react-query";
import LoadingFullScreen from "../../templates/LoadingFullScreen";

export const EditAccount: React.FC = () => {
  const { accountState } = React.useContext(AccountContext);
  const queryClient = useQueryClient();
  const { id } = useParams<{
    id: string;
  }>();
  const { isLoading, isError, data, error } = useQuery(`account-${id}`, () =>
    Api.fetch(accountDetailRequest(accountState.accessToken, Number(id)))
  );
  const [state, setState] = React.useState({
    error: "",
    account: null as null | Account,
    residentsAssociation: null as null | ResidentsAssociation,
  });
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      if (!isLoading && !isError && data.account) {
        setState((s) => ({
          ...s,
          account: data.account,
          residentsAssociation: data.account.residents_association,
        }));
      }
    })();
  }, [isLoading, isError, error, data]);

  const updateAccount = async (account: Account & { password: string }) => {
    setState((s) => ({ ...s, error: "" }));
    await Api.fetch(updateAccountRequest(accountState.accessToken, account));
  };

  const { mutate } = useMutation(updateAccount, {
    onSuccess: () => {
      history.push(
        generatePath(RESIDENTS_ASSOCIATION_INFO, {
          id: data.account.residents_association_id,
        })
      );
    },
    onError: (e: Error) => {
      setState((s) => ({ ...s, error: e.message }));
    },
    onSettled: () => {
      queryClient.invalidateQueries(`account-${id}`);
    },
  });
  return !state.account ? (
    <LoadingFullScreen />
  ) : (
    <AccountForm
      error={state.error}
      account={state.account}
      residentsAssociation={state.residentsAssociation!}
      upsertAccount={mutate}
    />
  );
};

export default EditAccount;
