import * as React from "react";
import {
  AccountReducer,
  AccountContext,
  InitialAccountState,
} from "./context/account";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LocalStorageKey } from "./definition";
import NoAuthedApp from "./routes/NoAuthedApp";
import AuthedApp from "./routes/AuthedApp";

const queryClient = new QueryClient();

function App() {
  const accessToken = localStorage.getItem(LocalStorageKey.AccessToken);

  const [accountState, accountDispatch] = React.useReducer(AccountReducer, {
    ...InitialAccountState,
    accessToken: accessToken || "",
    isNotAuth: true,
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AccountContext.Provider value={{ accountState, accountDispatch }}>
        {accountState.isNotAuth ? (
          <NoAuthedApp accessToken={accessToken} />
        ) : (
          <AuthedApp />
        )}
      </AccountContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
export default App;
