import React from "react";
import styles from "./AccountList.module.scss";
import { Account } from "../../../types/state";

export interface AccountListProps {
  accounts: Account[];
  toEditAccount: (id: number) => void;
}

export const AccountList: React.FC<AccountListProps> = (props) => {
  return (
    <>
      <table className="content-between table-fixed w-full">
        <thead>
          <tr>
            <th className="border w-12 px-4 py-2">ID</th>
            <th className="border w-1/6 px-4 py-2">名前 </th>
            <th className="border w-1/12 px-4 py-2">班</th>
            <th className="border w-1/12 px-4 py-2">状態</th>
            <th className="border w-1/12 px-4 py-2">役職</th>
            <th className="border w-1/6 px-4 py-2">連絡先</th>
            <th className="border w-1/6 px-4 py-2">住所</th>
            <th className="border w-2/12 px-4 py-2">メモ</th>
            <th className="border w-1/12 px-4 py-2"></th>
          </tr>
        </thead>
        <tbody>
          {props.accounts.map((ac) => {
            return (
              <tr key={ac.id}>
                <td className="border px-4 py-2">
                  <span className={styles.link}>{ac.id}</span>
                </td>
                <td className="border px-4 py-2 break-words">
                  {`${ac.last_name} ${ac.first_name}`}（
                  {`${ac.last_name_kana} ${ac.first_name_kana}`}）
                </td>
                <td className="border px-4 py-2 break-words">
                  {ac.group_name ? `${ac.group_name}` : ""}
                </td>
                <td className="border px-4 py-2 break-words">
                  {ac.status === "approved"
                    ? "承認済み"
                    : ac.status === "withdrawal"
                    ? "退会済み"
                    : "未承認"}
                </td>
                <td className="border break-words px-4 py-2">
                  {ac.is_represent ? "代表" : ac.is_officer ? "役員" : ""}{" "}
                  {ac.role ? `(${ac.role})` : ""}
                </td>
                <td className="border px-4 py-2 break-words">
                  {`TEL: ${ac?.tel}`}
                  <br />
                  {`Mail: ${ac?.email}`}
                </td>
                <td className="border px-4 py-2 break-words">{ac.address}</td>
                <td className="border px-4 py-2 break-words">{ac.memo}</td>
                <td className="border px-4 py-2">
                  <button
                    className={styles.btn}
                    onClick={() => {
                      props.toEditAccount(ac.id);
                    }}
                  >
                    編集
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default AccountList;
