import React from "react";
import styles from "./Header.module.scss";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export interface HeaderProps {
  name: string;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const location = useLocation();
  return (
    <div className={styles.header}>
      {location.pathname === "/" ? (
        <div />
      ) : (
        <Link to="/">&lt;&nbsp; 自治会一覧</Link>
      )}
      <div>{props.name}さん</div>
    </div>
  );
};
export default Header;
