import React from "react";
import styles from "./ResidentsAssociationList.module.scss";
import { IResidentsAssociationDetail } from "../../../types/state";
import { format } from "date-fns";

export interface ResidentsAssociationListProps {
  residentsAssociations: IResidentsAssociationDetail[];
  toResidentsAssociationInfo: (id: number) => void;
  toBuildNewResidentsAssociation: () => void;
}

export const ResidentsAssociationList: React.FC<ResidentsAssociationListProps> = (
  props
) => {
  return (
    <>
      <div className={styles.wrapper}>
        <button
          className={styles.btn}
          onClick={(e) => {
            e.preventDefault();
            props.toBuildNewResidentsAssociation();
          }}
        >
          新規自治会登録
        </button>

        <div className=" text-center pt-10 m-5 text-3xl  text-black">
          自治会一覧
        </div>
        <table className="content-between table-fixed w-full">
          <thead>
            <tr className="text-left">
              <th className="w-10 py-2">ID</th>
              <th className="w-28 py-2">自治会番号 </th>
              <th className="w-2/12 py-2">名前 </th>
              <th className="w-12 py-2">
                有/
                <br />
                無効
              </th>
              <th className="w-40 py-2">住所</th>
              <th className="w-3/12 py-2">連絡先</th>
              <th className="w-16 py-2">口座</th>
              <th className="w-40 py-2">自治会番号採番日時</th>
              <th className="w-2/12 py-2">メモ</th>
              <th className="w-20 py-2"></th>
            </tr>
          </thead>
          <tbody>
            {props.residentsAssociations.map((s, index) => {
              return (
                <tr key={s.id}>
                  <td className="border px-1 py-2">
                    <span className={styles.link}>{index + 1}</span>
                  </td>
                  <td className="border px-1 py-2 break-words">{s.local_authority_code}</td>
                  <td className="border px-1 py-2 break-words">{s.name}</td>
                  <td className="border text-center py-2 break-words">
                    {s.serviced ? "◯" : "x"}
                  </td>
                  <td className="border px-1 py-2 break-words">
                  {`${s.prefecture}${s.city_name || ""}`}
                  </td>
                  <td className="border px-1 py-2 break-words">
                    {`申請者名: ${s.creator_name || ""}`}
                    <br />
                    {`Mail: ${s.email}`}
                  </td>
                  <td className="border px-1 py-2">{s.bank_account ? "登録済" : "未登録"}</td>
                  <td className="border px-1 py-2 break-words">{s.created_at && format(new Date(s.created_at), 'yyyy/MM/dd HH:mm')}</td>
                  <td className="border px-1 py-2 break-words">{s.memo}</td>
                  <td className="border px-1 py-2 text-center">
                    <button
                      className={`${styles.btn} ${styles.detail}`}
                      onClick={() => {
                        props.toResidentsAssociationInfo(s.id);
                      }}
                    >
                      詳細
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ResidentsAssociationList;
