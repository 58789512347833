import * as React from "react";
import ResidentsAssociationForm from "../../templates/ResidentsAssociationForm";
import { ResidentsAssociation } from "../../../types/state";
import { createResidentsAssociationRequest } from "../../../api/residents_association";
import { Api } from "../../../libs/api";
import { useHistory } from "react-router";
import { RESIDENTS_ASSOCIATIONS } from "../../../routes/route";
import { AccountContext } from "../../../context/account";

const initialRegistrantAssociation: ResidentsAssociation = {
  id: 0,
  name: "",
  creator_name: "",
  prefecture: "",
  local_authority_code: "",
  city_name: "",
  email: "",
  memo: "",
  serviced: true,
};

export const NewResidentsAssociation: React.FC = () => {
  const { accountState } = React.useContext(AccountContext);
  const [state, setState] = React.useState({
    error: "",
  });
  const history = useHistory();
  const createResidentsAssociation = React.useCallback(
    (residentsAssociation: ResidentsAssociation) => {
      (async () => {
        Api.fetch(
          createResidentsAssociationRequest(
            accountState.accessToken,
            residentsAssociation
          )
        )
          .then(async (data) => {
            history.push(RESIDENTS_ASSOCIATIONS);
          })
          .catch((e) => {
            setState((s) => ({ ...s, error: e.message }));
          });
      })();
    },
    [history, accountState.accessToken]
  );
  return (
    <ResidentsAssociationForm
      error={state.error}
      residentsAssociation={initialRegistrantAssociation}
      upsertResidentsAssociation={createResidentsAssociation}
    />
  );
};

export default NewResidentsAssociation;
