export const authRequest = (accessToken: string) => {
  return {
    method: "GET",
    path: `/api/v1/admin/admins/auth`,
    accessToken,
  };
};

export const loginRequest = (email: string, password: string) => {
  return {
    method: "POST",
    path: `/api/v1/admin/admins/login`,
    data: { email, password },
  };
};

export const accountDetailRequest = (accessToken: string, id: number) => {
  return {
    method: "GET",
    accessToken,
    path: `/api/v1/admin/accounts/${id}`,
  };
};

export const createAccountRequest = (
  accessToken: string,
  args: {
    first_name: string;
    first_name_kana: string;
    last_name: string;
    last_name_kana: string;
    tel: string;
    email: string;
    memo: string;
    role: string;
    group_name: string;
    status: string;
    residents_association_id: number;
    is_officer: boolean;
    is_represent: boolean;
    password: string;
  }
) => {
  return {
    method: "POST",
    path: `/api/v1/admin/accounts`,
    accessToken,
    data: args,
  };
};

export const updateAccountRequest = (
  accessToken: string,
  args: {
    id: number;
    first_name: string;
    first_name_kana: string;
    last_name: string;
    last_name_kana: string;
    tel: string;
    email: string;
    memo: string;
    role: string;
    group_name: string;
    status: string;
    residents_association_id: number;
    is_officer: boolean;
    is_represent: boolean;
    password: string;
  }
) => {
  return {
    method: "PUT",
    path: `/api/v1/admin/accounts/${args.id}`,
    accessToken,
    data: args,
  };
};
