import React from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import IndexResidentsAssociation from "../components/pages/IndexResidentsAssociation";
import NewAccount from "../components/pages/NewAccount";
import EditAccount from "../components/pages/EditAccount";
import NewResidentsAssociation from "../components/pages/NewResidentsAssociation";
import ShowResidentsAssociation from "../components/pages/ShowResidentsAssociation";
import {
  EDIT_ACCOUNT,
  EDIT_RESIDENTS_ASSOCIATION,
  LOGIN,
  NEW_ACCOUNT,
  NEW_RESIDENTS_ASSOCIATION,
  RESIDENTS_ASSOCIATION_INFO,
} from "./route";
import EditResidentsAssociation from "../components/pages/EditResidentsAssociation";
import { AccountContext } from "../context/account";
import Header from "../components/organisms/Header";

export interface AuthedAppProps {}

export const AuthedApp: React.FC<AuthedAppProps> = () => {
  const { accountState } = React.useContext(AccountContext);
  return (
    <Router>
      <Header name={accountState.account.name} />
      <Switch>
        <Route exact={true} path={NEW_RESIDENTS_ASSOCIATION}>
          <NewResidentsAssociation />
        </Route>
        <Route exact={true} path={"/"}>
          <IndexResidentsAssociation />
        </Route>
        <Route exact={true} path={RESIDENTS_ASSOCIATION_INFO}>
          <ShowResidentsAssociation />
        </Route>
        <Route exact={true} path={NEW_ACCOUNT}>
          <NewAccount />
        </Route>
        <Route exact={true} path={EDIT_RESIDENTS_ASSOCIATION}>
          <EditResidentsAssociation />
        </Route>
        <Route exact={true} path={EDIT_ACCOUNT}>
          <EditAccount />
        </Route>
        <Route exact={true} path={LOGIN}>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};
export default AuthedApp;
