import React from "react";
import styles from "./LoginForm.module.scss";

export interface LoginFormProps {
  error: string;
  login: (email: string, password: string) => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["email"] = (val) => {
  return val.email.match(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  )
    ? ""
    : "正しいメールアドレスを入力してください。";
};

validates["password"] = (val) => {
  return val.password.length < 6 || val.password.length > 32
    ? "パスワードは6文字以上32文字以内で入力してください。"
    : "";
};

export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const [state, setState] = React.useState({
    email: "",
    password: "",
    isSubmitted: false,
    errorMessages: {} as { [key: string]: string },
  });

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach((key) => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState((s) => ({ ...s, errorMessages }));
        return;
      }
      props.login(state.email, state.password);
    },
    [state, props]
  );

  const onChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.preventDefault();
      const val = e.currentTarget.value;
      const key = e.currentTarget.name;
      const args = { [key]: val };
      const ret = validates[key](args);
      setState((prevState) => ({
        ...prevState,
        [key]: val,
        errorMessages: { ...prevState.errorMessages, [key]: ret },
      }));
    },
    []
  );

  return (
    <div>
      <div className="p-10 max-w-sm mx-auto bg-white rounded-xl shadow-md items-center space-x-4">
        <div className="text-center pt-10 text-xl font-medium text-black">
          管理者 ログイン
        </div>
        <div className={styles.inputAreaWrapper}>
          <form
            onSubmit={onSubmit}
            className={state.isSubmitted ? "was-validated" : ""}
          >
            {props.error && (
              <div className={"mt-5 " + styles.errorMessage}>{props.error}</div>
            )}
            <div className="grid grid-cols-1 gap-6">
              <label className="block mt-5">
                <span className="text-gray-700">メールアドレス</span>
                <input
                  type="email"
                  name="email"
                  className={styles.input}
                  id="email"
                  value={state.email}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["email"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["email"]}
                </div>
              )}
            </div>
            <label className="block mt-10">
              <span className="text-gray-700">パスワード</span>
              <input
                type="password"
                id="password"
                name="password"
                className={styles.input}
                value={state.password}
                onChange={onChange}
              />
              {state.isSubmitted && state.errorMessages["password"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["password"]}
                </div>
              )}
            </label>
            <div className="p-10 grid grid-cols-1 gap-6">
              <button
                className={styles.btn}
                onClick={onSubmit}
                disabled={
                  Object.keys(state.errorMessages).filter(
                    (k) => state.errorMessages[k]
                  ).length > 0
                }
              >
                ログイン
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
