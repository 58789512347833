import * as React from "react";
import AccountForm from "../../templates/AccountForm";
import { Account, ResidentsAssociation } from "../../../types/state";
import { residentsAssociationRequest } from "../../../api/residents_association";
import { createAccountRequest } from "../../../api/account";
import { Api } from "../../../libs/api";
import { generatePath, useHistory, useParams } from "react-router";
import { RESIDENTS_ASSOCIATION_INFO } from "../../../routes/route";
import { AccountContext } from "../../../context/account";
import { useQuery } from "react-query";
import LoadingFullScreen from "../../templates/LoadingFullScreen";

const initialAccount: Account = {
  id: 0,
  first_name: "",
  first_name_kana: "",
  last_name: "",
  last_name_kana: "",
  email: "",
  tel: "",
  memo: "",
  residents_association_id: 0,
  role: "",
  group_name: "",
  status: "approved",
  is_officer: true,
  is_represent: true,
  address: "",
};

export const NewAccount: React.FC = () => {
  const { accountState } = React.useContext(AccountContext);
  const { residentsAssociationId } = useParams<{
    residentsAssociationId: string;
  }>();
  const {
    isLoading,
    isError,
    data,
    error,
  } = useQuery(`residentsAssociation-${residentsAssociationId}`, () =>
    Api.fetch(
      residentsAssociationRequest(
        accountState.accessToken,
        Number(residentsAssociationId)
      )
    )
  );
  const [state, setState] = React.useState({
    error: "",
    account: {
      ...initialAccount,
      residents_association_id: Number(residentsAssociationId),
    },
    residentsAssociation: null as null | ResidentsAssociation,
  });
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      if (!isLoading && !isError) {
        setState((s) => ({
          ...s,
          residentsAssociation: data.residents_association,
        }));
      }
    })();
  }, [isLoading, isError, error, data]);

  const createAccount = React.useCallback(
    (account: Account & { password: string }) => {
      (async () => {
        Api.fetch(createAccountRequest(accountState.accessToken, account))
          .then(async (_) => {
            history.push(
              generatePath(RESIDENTS_ASSOCIATION_INFO, {
                id: residentsAssociationId,
              })
            );
          })
          .catch((e) => {
            setState((s) => ({ ...s, error: e.message }));
          });
      })();
    },
    [history, accountState.accessToken, residentsAssociationId]
  );
  return !state.residentsAssociation ? (
    <LoadingFullScreen />
  ) : (
    <AccountForm
      error={state.error}
      account={state.account}
      residentsAssociation={state.residentsAssociation!}
      upsertAccount={createAccount}
    />
  );
};

export default NewAccount;
