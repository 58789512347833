import * as React from "react";
import { diff } from "../libs/diff";

export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_LOGIN_RESULT = "SET_LOGIN_RESULT";
export const LOGOUT = "LOGOUT";

export type Account = {
  name: string;
};
export type AccountState = {
  accessToken: string;
  isNotAuth: boolean;
  error?: string;
  account: Account;
};

export const InitialAccountState: AccountState = {
  accessToken: "",
  isNotAuth: true,
  account: {
    name: "",
  },
};

export const setLoginResult = (response: {
  account: Account;
  token: string;
}) => {
  return {
    type: SET_LOGIN_RESULT as typeof SET_LOGIN_RESULT,
    payload: response,
  };
};

export const setAccount = (account: Account) => {
  return {
    type: SET_ACCOUNT as typeof SET_ACCOUNT,
    payload: {
      account,
    },
  };
};
export const logout = (error = "") => {
  return {
    type: LOGOUT as typeof LOGOUT,
    payload: {
      error,
    },
  };
};
export type AccountActions =
  | ReturnType<typeof setAccount>
  | ReturnType<typeof setLoginResult>
  | ReturnType<typeof logout>;

const reducer = (state: AccountState, action: AccountActions): AccountState => {
  switch (action.type) {
    case SET_LOGIN_RESULT: {
      return {
        ...state,
        isNotAuth: false,
        account: action.payload.account,
        accessToken: action.payload.token,
      };
    }
    case SET_ACCOUNT: {
      return { ...state, isNotAuth: false, account: action.payload.account };
    }
    case LOGOUT: {
      return { ...InitialAccountState, error: action.payload.error };
    }
    default:
      return state;
  }
};

export const AccountReducer =
  process.env.NODE_ENV === "production"
    ? reducer
    : (state: AccountState, action: AccountActions) => {
        console.groupCollapsed(action.type);
        console.log("action", action);
        const result = reducer(state, action);
        console.log("diff", diff(state, result));
        console.log("prevState", state);
        console.log("currentState", result);
        console.groupEnd();
        return result;
      };

type ContextState = {
  accountState: AccountState;
  accountDispatch(action: AccountActions): void;
};

export const AccountContext = React.createContext<ContextState>({
  accountState: InitialAccountState,
  accountDispatch(_) {
    console.warn("Context.Provider外からの呼び出し");
  },
});
