import React from "react";
import { Account, ResidentsAssociation } from "../../../types/state";
import styles from "./AccountForm.module.scss";

export interface AccountFormProps {
  error: string;
  residentsAssociation: ResidentsAssociation;
  account: Account;
  upsertAccount: (account: Account & { password: string }) => void;
}

const validates: { [key: string]: (value: any) => string } = {};

validates["email"] = (val) => {
  return val.status === "withdrawal" ||
    val.email.match(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    )
    ? ""
    : "正しいメールアドレスを入力してください。";
};

validates["tel"] = (val) => {
  return !val.tel || val.tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)
    ? ""
    : "電話番号の入力に誤りがあります。";
};

validates["first_name"] = (val) => {
  return val.first_name === "" ? "名前を入力してください。" : "";
};

validates["last_name"] = (val) => {
  return val.last_name === "" ? "名字を入力してください。" : "";
};

validates["last_name_kana"] = (val) => {
  return val.last_name_kana === "" ? "名字(かな)を入力してください。" : "";
};

validates["first_name_kana"] = (val) => {
  return val.first_name_kana === "" ? "名前(かな)を入力してください。" : "";
};

export const AccountForm: React.FC<AccountFormProps> = (props) => {
  const [state, setState] = React.useState({
    ...props.account,
    password: "",
    isPasswordEdit: !props.account.id,
    isSubmitted: false,
    errorMessages: {} as { [key: string]: string },
  });
  React.useEffect(() => {
    if (props.error) {
      window.scrollTo(0, 0);
    }
  }, [props.error]);

  const onSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach((key) => {
        const ret = validates[key](state);
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState((s) => ({ ...s, isSubmitted: true, errorMessages }));
        return;
      }
      props.upsertAccount(state);
    },
    [state, props]
  );

  const onChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
      const val = e.currentTarget.value;
      const key = e.currentTarget.name;
      const args = { [key]: val };
      const ret = validates[key] ? validates[key](args) : "";
      setState((prevState) => ({
        ...prevState,
        [key]: val,
        errorMessages: { ...prevState.errorMessages, [key]: ret },
      }));
    },
    []
  );

  return (
    <div>
      <div className="mt-5 p-5 max-w-sm mx-auto bg-white rounded-xl shadow-md items-center space-x-4">
        <div className="text-center pt-10 text-xl font-medium text-black">
          {props.residentsAssociation.name}
          <br />
          会員登録
        </div>
        <div className={"w-full max-w-xs"}>
          <form onSubmit={onSubmit}>
            {props.error && (
              <div className={"mt-5 " + styles.errorMessage}>{props.error}</div>
            )}
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  名字
                </span>
                <input
                  type="text"
                  name="last_name"
                  className={styles.input}
                  id="last_name"
                  value={state.last_name}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["last_name"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["last_name"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  名前
                </span>
                <input
                  type="text"
                  name="first_name"
                  className={styles.input}
                  id="first_name"
                  value={state.first_name}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["first_name"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["first_name"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  名字(かな)
                </span>
                <input
                  type="text"
                  name="last_name_kana"
                  className={styles.input}
                  id="last_name_kana"
                  value={state.last_name_kana}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["last_name_kana"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["last_name_kana"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  名前(かな)
                </span>
                <input
                  type="text"
                  name="first_name_kana"
                  className={styles.input}
                  id="first_name_kana"
                  value={state.first_name_kana}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["first_name_kana"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["first_name_kana"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  電話番号
                </span>
                <input
                  type="tel"
                  name="tel"
                  className={styles.input}
                  id="tel"
                  value={state.tel}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["tel"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["tel"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  メールアドレス
                </span>
                <input
                  type="email"
                  name="email"
                  className={styles.input}
                  id="email"
                  value={state.email}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["email"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["email"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  パスワード
                </span>
                {state.isPasswordEdit ? (
                  <input
                    type="password"
                    name="password"
                    className={styles.input}
                    id="password"
                    value={state.password}
                    onChange={onChange}
                  />
                ) : (
                  <button
                    className={styles.btn}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ ...state, isPasswordEdit: true });
                    }}
                  >
                    編集
                  </button>
                )}
              </label>
              {state.isSubmitted && state.errorMessages["password"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["password"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  役職
                </span>
                <input
                  type="text"
                  name="role"
                  className={styles.input}
                  id="role"
                  value={state.role}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["role"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["role"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  班
                </span>
                <input
                  type="text"
                  name="group_name"
                  className={styles.input}
                  id="group_name"
                  value={state.group_name}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["group_name"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["group_name"]}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  住所
                </span>
                <input
                  type="text"
                  name="address"
                  className={styles.input}
                  id="address"
                  value={state.address}
                  onChange={onChange}
                  maxLength={255}
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  代表者
                </span>
                <div className="mt-2">
                  <div className="relative inline-block w-16 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      checked={state.is_represent}
                      id={"is_represent"}
                      name="is_represent"
                      onChange={(event) => {
                        const val = event.target.checked;
                        setState((prevState) => ({
                          ...prevState,
                          is_represent: val,
                          is_officer: val ? true : prevState.is_officer,
                        }));
                      }}
                      className={`${styles.toggleCheckbox} absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer`}
                    />
                    <label
                      htmlFor="is_represent"
                      className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                    ></label>
                  </div>
                </div>
              </label>
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  運営
                </span>
                <div className="mt-2">
                  <div className="relative inline-block w-16 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      checked={state.is_officer}
                      id={"is_officer"}
                      name="is_officer"
                      disabled={state.is_represent}
                      onChange={(event) => {
                        const name = "is_officer";
                        const val = event.target.checked;
                        setState((prevState) => ({
                          ...prevState,
                          [name]: val,
                        }));
                      }}
                      className={`${styles.toggleCheckbox} absolute block w-6 h-6 rounded-full bg-white border-2 appearance-none cursor-pointer`}
                    />
                    <label
                      htmlFor="is_officer"
                      className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                    ></label>
                  </div>
                </div>
              </label>
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  状態
                </span>
                <div className="mt-2">
                  <div
                    className={`mx-auto max-w-sm text-center flex flex-wrap  ${styles.radio}`}
                  >
                    <div className="flex items-center mr-4 mb-4">
                      <input
                        id="radio2"
                        type="radio"
                        name="status"
                        className="hidden"
                        checked={state.status === "approved"}
                        onChange={() =>
                          setState({ ...state, status: "approved" })
                        }
                      />
                      <label
                        htmlFor="radio2"
                        className="flex items-center cursor-pointer"
                      >
                        <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                        承認済
                      </label>
                    </div>
                    <div className="flex items-center mr-4 mb-4">
                      <input
                        id="radio3"
                        type="radio"
                        name="status"
                        className="hidden"
                        checked={state.status === "withdrawal"}
                        onChange={(e) =>
                          setState({ ...state, status: "withdrawal" })
                        }
                      />
                      <label
                        htmlFor="radio3"
                        className="flex items-center cursor-pointer"
                      >
                        <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                        退会
                      </label>
                    </div>
                  </div>
                </div>
              </label>
            </div>
            <div className="mb-4">
              <label className="block mt-5">
                <span className="block text-gray-700 text-sm font-bold mb-2">
                  メモ
                </span>

                <textarea
                  name="memo"
                  className={styles.textArea}
                  id="memo"
                  value={state.memo}
                  onChange={onChange}
                />
              </label>
              {state.isSubmitted && state.errorMessages["memo"] && (
                <div className={styles.errorMessage}>
                  {state.errorMessages["memo"]}
                </div>
              )}
            </div>
            <div className="p-10 grid grid-cols-1 gap-6">
              <button className={styles.btn} onClick={onSubmit}>
                保存
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountForm;
