import * as React from "react";
import ResidentsAssociationForm from "../../templates/ResidentsAssociationForm";
import { ResidentsAssociation } from "../../../types/state";
import {
  residentsAssociationRequest,
  updateResidentsAssociationRequest,
} from "../../../api/residents_association";
import { Api } from "../../../libs/api";
import { generatePath, useHistory, useParams } from "react-router";
import { RESIDENTS_ASSOCIATION_INFO } from "../../../routes/route";
import { AccountContext } from "../../../context/account";
import { useMutation, useQuery, useQueryClient } from "react-query";
import LoadingFullScreen from "../../templates/LoadingFullScreen";

export const EditResidentsAssociation: React.FC = () => {
  const { accountState } = React.useContext(AccountContext);
  const queryClient = useQueryClient();
  const { id } = useParams<{
    id: string;
  }>();
  const {
    isLoading,
    isError,
    data,
    error,
  } = useQuery(`residentsAssociation-${id}`, () =>
    Api.fetch(residentsAssociationRequest(accountState.accessToken, Number(id)))
  );
  const [state, setState] = React.useState({
    error: "",
    residentsAssociation: null as null | ResidentsAssociation,
  });
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      if (!isLoading && !isError) {
        setState((s) => ({
          ...s,
          residentsAssociation: data.residents_association,
        }));
      }
    })();
  }, [isLoading, isError, error, data]);

  const updateResidentsAssociation = async (
    residentsAssociation: ResidentsAssociation
  ) => {
    setState((s) => ({ ...s, error: "" }));
    await Api.fetch(
      updateResidentsAssociationRequest(
        accountState.accessToken,
        residentsAssociation
      )
    );
  };

  const { mutate } = useMutation(updateResidentsAssociation, {
    onSuccess: () => {
      history.push(
        generatePath(RESIDENTS_ASSOCIATION_INFO, {
          id: id,
        })
      );
    },
    onError: (e: Error) => {
      setState((s) => ({ ...s, error: e.message }));
    },
    onSettled: () => {
      queryClient.invalidateQueries(`residentsAssociation-${id}`);
    },
  });
  return !state.residentsAssociation ? (
    <LoadingFullScreen />
  ) : (
    <ResidentsAssociationForm
      error={state.error}
      residentsAssociation={state.residentsAssociation}
      upsertResidentsAssociation={mutate}
      isEdit
    />
  );
};

export default EditResidentsAssociation;
